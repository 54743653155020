<template>
	<v-data-table v-bind="$attrs" class="w-data-table" :headers="headers" :items="listOfItems" v-on="$listeners">
		<slot v-for="slot in Object.keys($slots)" :v-slot="slot" :name="slot"></slot>
		<!-- Pass on all scoped slots -->
		<template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
			<slot
				:name="slot"
				v-bind="{
					...scope,
					index: scope && scope.item ? scope.item.index : scope && scope.index ? scope.index : undefined,
					item: scope && scope.item ? scope.item.item : undefined
				}"
			>
			</slot>
		</template>
	</v-data-table>
</template>

<script>
/**

 * @displayName w-data-table
 * 
 * @since 0.3.2
 */
export default {
	name: 'WDataTable',
	props: {
		items: {
			required: true,
			type: Array
		},
		headers: {
			required: true,
			type: Array
		}
	},
	computed: {
		/**
		 * When the component WDataTable sort the array items, the index sent is wrong so we made this
		 */
		listOfItems: function () {
			let items = [...this.items]

			if (!items) {
				items = []
			}

			return items.map((item, index) => {
				return {
					item: item,
					index
				}
			})
		}
	}
}
</script>

<style scoped>
.w-data-table {
	display: grid;
	grid-template-rows: 1fr auto;
	max-height: 100%;
}

:deep(.v-table__overflow) {
	overflow-y: auto;
}

:deep(.theme--dark th) {
	background-color: #424242;
}

:deep(thead th) {
	background-color: #ffffff;
	position: sticky;
	top: 0;
	z-index: 1;
}

:deep(td.text-xs-center .v-menu.v-small-dialog.v-menu--inline .v-menu__activator a) {
	margin: auto;
}
</style>
